import {
  defer,
  LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/cloudflare";
import { Await, useLoaderData } from "@remix-run/react";
import { LoadingIndicator } from "@thisisbud/gds-ui/loading-indicator";
import { Suspense } from "react";
import { getProviderStatuses } from "~/api/get-provider-status";
import { ColorScaleLegend } from "~/components/color-scale-legend";
import { UptimeAvailability } from "~/components/uptime-availability";
import { UPTIME_COLOR_SCALE } from "~/constants/colors";
import { t } from "~/locale/en";

export const meta: MetaFunction = () => {
  return [
    { title: "Open Banking API status" },
    {
      name: "description",
      content: "Get the status of open banking supported banks in the UK.",
    },
  ];
};

export const loader = async ({ context }: LoaderFunctionArgs) =>
  defer(
    {
      data: getProviderStatuses(
        context.cloudflare.env.PROVIDER_STATUS,
        context.cloudflare.env.API_HOSTS
      ),
    },
    {
      headers: {
        "Cache-Control": "max-age=900, s-maxage=900",
      },
    }
  );

export default function Index() {
  const { data } = useLoaderData<typeof loader>();

  return (
    <div className="max-w-5xl">
      <h1 className="mt-9 text-2xl">{"Hello world!"}</h1>
      <h1 className="mt-9 text-2xl">{t.title}</h1>
      <p className="mt-4">{t.subtitle}</p>
      <p className="mt-12 text-lg">{t.availability}</p>
      <div className="mt-4 flex flex-wrap justify-between gap-10">
        <div className="flex gap-10 self-end">
          <div className="flex">
            <div className="size-6 rounded-[4px] bg-[#000]"></div>
            <span className="ml-4">{t.legend_maintenance}</span>
          </div>
          <div className="flex">
            <div className="size-6 rounded-[4px] border border-surface-border bg-[#F3F4F6]"></div>
            <span className="ml-4">{t.legend__no_data}</span>
          </div>
        </div>
        <ColorScaleLegend
          id="test-id"
          label={t.legend_label}
          scale={UPTIME_COLOR_SCALE}
          xAxisSteps={6}
        />
      </div>
      <Suspense
        fallback={
          <div className="my-40 grid justify-center items-center gap-4 grid-flow-col">
            <LoadingIndicator />
            {t.loading}
          </div>
        }
      >
        <Await
          resolve={data}
          errorElement={<p className="mt-4">{t.error__unknown}</p>}
        >
          {(status) => (
            <UptimeAvailability
              scale={UPTIME_COLOR_SCALE}
              status={status.data}
            />
          )}
        </Await>
      </Suspense>
    </div>
  );
}
