import { ColorScale } from "~/components/color-scale-legend";

export const NO_DATA_COLOR = "#F3F4F6";

export const UPTIME_COLOR_SCALE: ColorScale[] = [
  { step: 0, color: "#000000" },
  { step: 10, color: "#5B1700" },
  { step: 20, color: "#B83000" },
  { step: 30, color: "#F26223" },
  { step: 40, color: "#FF9352" },
  { step: 50, color: "#F5A623" },
  { step: 60, color: "#FED52F" },
  { step: 70, color: "#D6DF18" },
  { step: 80, color: "#AEEA00" },
  { step: 100, color: "#23A350" },
];
