import { renderGradientStops, renderXaxis } from "./utils";

export type ColorScale = {
  step: 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
  color: `#${string}` | `rgb(${number},${number},${number})`;
};

type Props = {
  id: string;
  label: string;
  scale: ColorScale[];
  xAxisSteps?: number;
};

export const ColorScaleLegend = ({ id, label, scale, xAxisSteps }: Props) => {
  const labelId = `${id}-label`;
  const hueGradientId = `${id}-hue-gradient`;

  return (
    <div className="flex items-end" id={id} aria-hidden>
      <span id={labelId}>{label}</span>
      <div className="ml-4">
        <div className="flex justify-between">
          {renderXaxis(scale, xAxisSteps)}
        </div>
        <svg
          className="mt-1 h-6 w-full"
          key="svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <linearGradient id={hueGradientId}>
              {renderGradientStops(scale)}
            </linearGradient>
          </defs>
          <rect
            fill={`url(#${hueGradientId})`}
            height="100%"
            rx={4}
            ry={4}
            width="100%"
          />
        </svg>
      </div>
    </div>
  );
};
