import { ProviderStatuses } from "~/components/tip";

export const sortStatusesByDisplayName = (statusesObj: ProviderStatuses) => {
  const sortedEntries = Object.entries(statusesObj).sort((a, b) => {
    const displayNameA = a[1].display_name;
    const displayNameB = b[1].display_name;
    return displayNameA.localeCompare(displayNameB);
  });

  return Object.fromEntries(sortedEntries);
};
