export const formatTipDateTime = (isoDateString: string): string => {
  const date = new Date(isoDateString);

  const formattedDate = date
    .toLocaleString("en-GB", {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(",", "");

  return formattedDate;
};
