import { formatTipDateTime } from "../../utils/format-time";
import { formatPercentage } from "../../utils/format-percentage";
import { t } from "~/locale/en";

export type ProviderStatus = {
  provider: string;
  from: string;
  to: string;
  count: number;
  avg_response_ms: number;
  has_been_in_maintenance: boolean;
  performance: number;
  sources: string[] | null;
  request_types: string[] | null;
  metadata?: null | unknown;
};

export type ProviderStatuses = {
  [key: string]: {
    display_name: string;
    statuses: ProviderStatus[];
  };
};

type Props = {
  providerName: string;
  item: ProviderStatus;
};

export const Tip = ({ item, providerName }: Props) => {
  return (
    <div>
      <div className="flex justify-between">
        <span>{t.tip__time__start} </span>
        <span className="ml-2">{formatTipDateTime(item.from)}</span>
      </div>
      <div className="flex justify-between">
        <span>{t.tip__time__end} </span>
        <span className="ml-2">{formatTipDateTime(item.to)}</span>
      </div>
      <div className="flex justify-between">
        <span>{t.tip__provider} </span>
        <span className="ml-2">{providerName}</span>
      </div>
      <div className="flex justify-between">
        <span>{t.availability} </span>
        <span className="ml-2">{formatPercentage(item.performance)}</span>
      </div>
    </div>
  );
};
