import { ColorScale } from ".";

export function renderGradientStops(colorScale: ColorScale[]) {
  return [...colorScale]
    .sort((a, b) => Number(b.step) - Number(a.step))
    .map(({ step, color }) => {
      const offset = (1 - Number(step) / 100).toFixed(1);

      return <stop key={offset} offset={offset} stopColor={color} />;
    });
}

export function renderXaxis(colorScale: ColorScale[], xAxisSteps?: number) {
  const steps = [];

  const axisLength = xAxisSteps ?? colorScale.length;

  const step = 100 / (axisLength - 1);

  for (let i = 0; i < axisLength; i++) {
    steps.unshift(i * step);
  }

  return steps.map((key: number) => {
    return (
      <span className="text-xs" key={key}>
        {key.toFixed()}
      </span>
    );
  });
}
