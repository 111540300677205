export const t = {
  title: "Open Banking API status",
  subtitle: "Get the status of open banking supported banks in the UK.",
  availability: "API availability (past 24 hours)",
  legend_label: "Availability (%)",
  legend_maintenance: "In maintenance",
  legend__no_data: "No data",
  loading: "Loading...",
  error__not_found: "Looks like you tried to visit a page that does not exist.",
  error__unknown:
    "Failed to retrieve Open Banking API statuses. Please try again later.",
  tip__time__start: "Start time:",
  tip__time__end: "End time:",
  tip__provider: "Provider:",
  tip__availability: "Availability:",
} as const;
