import { ColorScale } from "~/components/color-scale-legend";
import { ProviderStatus } from "~/components/tip";
import { NO_DATA_COLOR } from "../constants/colors";

export const hexToRgb = (hex: string): [number, number, number] => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

export const interpolateColor = (
  color1: [number, number, number],
  color2: [number, number, number],
  factor: number
): string => {
  const r = Math.round(color1[0] + factor * (color2[0] - color1[0]));
  const g = Math.round(color1[1] + factor * (color2[1] - color1[1]));
  const b = Math.round(color1[2] + factor * (color2[2] - color1[2]));

  return `rgb(${r},${g},${b})`;
};

export const rgbStringToRgb = (rgbString: string): [number, number, number] => {
  const match = rgbString.match(/\d+/g);
  if (match) {
    return [
      parseInt(match[0], 10),
      parseInt(match[1], 10),
      parseInt(match[2], 10),
    ];
  }
  throw new Error("Invalid RGB string");
};

export const getUptimeScaleColor = (
  scale: ColorScale[],
  status: ProviderStatus
) => {
  const { performance, count, has_been_in_maintenance: inMaintenance } = status;
  let scaledPerformance = Math.floor(performance * 100);

  if (count === 0) {
    return NO_DATA_COLOR;
  }

  if (scaledPerformance === 0 && inMaintenance) {
    scaledPerformance = 0;
  }

  // Sort the array in ascending order by step
  const sortedScale = [...scale].sort((a, b) => a.step - b.step);

  for (let i = 0; i < sortedScale.length - 1; i++) {
    const currentStep = sortedScale[i].step;
    const nextStep = sortedScale[i + 1].step;
    const currentColor = sortedScale[i].color;
    const nextColor = sortedScale[i + 1].color;

    if (scaledPerformance >= currentStep && scaledPerformance <= nextStep) {
      const factor =
        (scaledPerformance - currentStep) / (nextStep - currentStep);

      const color1 = currentColor.startsWith("#")
        ? hexToRgb(currentColor)
        : rgbStringToRgb(currentColor);
      const color2 = nextColor.startsWith("#")
        ? hexToRgb(nextColor)
        : rgbStringToRgb(nextColor);

      return interpolateColor(color1, color2, factor);
    }
  }

  return sortedScale[sortedScale.length - 1].color; // default to the last color if not found in any range
};
