import { Toggletip } from "@thisisbud/gds-ui/toggletip";

import { ProviderStatuses, Tip } from "../tip";

import { getUptimeScaleColor } from "../../utils/format-color";
import { sortStatusesByDisplayName } from "../../utils/statuses";
import { ColorScale } from "../color-scale-legend";

type Props = {
  status: ProviderStatuses;
  scale: ColorScale[];
};

export const UptimeAvailability = ({ scale, status }: Props) => {
  const sortedStatuses = sortStatusesByDisplayName(status);
  const mappedSortedStatuses = Object.entries(sortedStatuses);

  return (
    <div className="mt-8 flex justify-between">
      <div>
        {mappedSortedStatuses.map(([provider, providerStatuses]) => (
          <p className="mt-[1px] flex h-8 items-center truncate" key={provider}>
            {providerStatuses.display_name}
          </p>
        ))}
      </div>
      <div className="ml-2 overflow-auto">
        {mappedSortedStatuses.map(([provider, providerStatuses]) => {
          const { display_name, statuses } = providerStatuses;
          return (
            <div
              key={provider}
              className="mt-px flex h-8 max-w-screen-md gap-x-px"
            >
              {statuses.map((item, index) => (
                <Toggletip
                  tip={<Tip item={item} providerName={display_name} />}
                  key={`${item.provider}-${index}`}
                >
                  <div
                    style={{
                      backgroundColor: getUptimeScaleColor(scale, item),
                    }}
                    className="h-8 w-8"
                  />
                </Toggletip>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
